import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-item',
  templateUrl: './simple-item.component.html',
  styleUrls: ['./simple-item.component.scss']
})
export class SimpleItemComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
